/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { useLazyQuery } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { decodeBase64 } from "../../../constants/yotpoReviews";
import { GET_PLAN_BY_PRODUCT_ID } from "../../../g/queries/get-plans";
import { AddToCartButton } from "../../atoms/AddToCartButton/AddToCartButton";
import { get } from "lodash";

import * as s from "./ProductPackageCard.module.scss";
import Checkbox from "../../atoms/Checkbox/Checkbox";
import { getCheckbox } from "../ProductInfo/helper";

interface ProductPackageCardProps {
  title: string;
  haveOption: boolean;
  product: ProductNode;
  price?: number;
}

const ProductPackageCard = ({
  title,
  haveOption,
  product,
  price,
}: ProductPackageCardProps) => {
  const [last, setLast] = useState<string | undefined>("");
  const [beforeLast, setBeforeLast] = useState<string>("");
  const [discount, setDiscount] = useState(1);
  const [variant, setVariant] = useState<any>(product?.node?.variants[0]);
  const [quantity, setQuantity] = useState<number>(1);

  const [plans, setPlans] = useState<IPlans>({
    id: "",
    name: "",
    priceAdjustments: [],
  });

  const checkboxOptions = getCheckbox(
    parseInt(product?.node?.variants[0].price),
    product?.node?.variants[0]?.priceV2?.currencyCode,
    discount,
    true
  );

  const [planId, setPlanId] = useState<string | null>(null);
  const [check, setCheck] = useState<{
    id: string;
    price: number;
    title: string;
  }>(checkboxOptions[0]);

  useEffect(() => {
    const lastWord = title.split(" ").pop();
    const lastIndexOfSpace = title.lastIndexOf(" ");
    const beforeLastWord = title.substring(0, lastIndexOfSpace);
    setLast(lastWord);
    setBeforeLast(beforeLastWord);
    if (haveOption) {
      setCheck(checkboxOptions[1]);
    }
  }, []);

  useEffect(() => {
    setPlanId(plans.id);
  }, [discount]);

  const [sellingPlans] = useLazyQuery(GET_PLAN_BY_PRODUCT_ID, {
    fetchPolicy: "network-only",
    onCompleted: ({ product }) => {
      if (
        product?.sellingPlanGroups?.edges &&
        product?.sellingPlanGroups?.edges[0]?.node &&
        product?.sellingPlanGroups?.edges[0]?.node?.sellingPlans?.edges &&
        product?.sellingPlanGroups?.edges[0]?.node?.sellingPlans?.edges[0]?.node
      ) {
        console.log("product", product);
        const data =
          product?.sellingPlanGroups?.edges[0]?.node?.sellingPlans?.edges[0]
            ?.node;
        const adminId = "gid://shopify/SellingPlan/" + decodeBase64(data?.id);
        setPlans({
          ...data,
          id: adminId,
        });
        console.log("data", data);
        const adjustment = get(
          data.priceAdjustments[0],
          "adjustmentValue.adjustmentPercentage"
        );
        setDiscount(adjustment);
      }
    },
  });

  useEffect(() => {
    const id =
      "gid://shopify/Product/" + decodeBase64(product?.node?.shopifyId || "");
    sellingPlans({
      variables: {
        id,
      },
    });
    setVariant(product?.node?.variants[0]);
  }, [product]);

  const handleChecked = (c: { id: string; price: number; title: string }) => {
    if (c.id === "sub") {
      setPlanId(plans.id);
    } else {
      setPlanId(null);
      setVariant(product?.node?.variants[0]);
    }
    setCheck(c);
  };

  return (
    <div className={s.container}>
      {product && (
        <div
          className={s.imageContainer}
          style={{
            backgroundImage: `url(${product?.node?.images[0].originalSrc})`,
          }}
        >
          {/* <img
            src={product?.node?.images[0]?.originalSrc}
            alt="Packages Of Gummies"
          /> */}
        </div>
      )}
      <div className={s.descriptionContainer}>
        <div className={s.textContainer}>
          <p className={s.title}>
            {beforeLast} <span className={s.titleStrong}>{last}</span>{" "}
          </p>
          <p className={s.description}>{product?.node?.description} </p>
          {!haveOption && price && (
            <p className={s.checkboxText}>
              {" "}
              Prepay to save
              <span
                style={{ textDecoration: "line-through", marginLeft: "4px" }}
              >
                ${title.includes("3") ? 3 * price : 6 * price}
              </span>
              <span style={{ color: "#16b76a", marginLeft: "4px" }}>
                {" "}
                ${Number(variant?.priceV2?.amount).toFixed(0)}{" "}
              </span>
            </p>
          )}
        </div>
        {haveOption && checkboxOptions && (
          <div>
            <div
              className={s.checkboxContainer}
              key={checkboxOptions[0].id}
              onClick={() => handleChecked(checkboxOptions[0])}
            >
              <Checkbox
                value={checkboxOptions[0].title}
                check={check?.id === checkboxOptions[0].id}
              />
            </div>
            <p className={s.subscribeText}>
              Subscribe & Save ({Number(discount).toFixed(0)}%)
            </p>
            <div
              className={s.checkboxContainer}
              key={checkboxOptions[1].id}
              onClick={() => handleChecked(checkboxOptions[1])}
            >
              <Checkbox
                value={checkboxOptions[1].title}
                check={check?.id === checkboxOptions[1].id}
              />
            </div>
          </div>
        )}
        {variant && haveOption && (
          <div className={s.buttonContainer}>
            <AddToCartButton
              quantity={quantity}
              setQuantity={setQuantity}
              isBuyNow={false}
              availableForSale={true}
              variant={variant}
              planId={planId}
            />
          </div>
        )}
        {variant && !haveOption && (
          <div className={s.buttonContainer}>
            <AddToCartButton
              quantity={quantity}
              setQuantity={setQuantity}
              isBuyNow={false}
              availableForSale={true}
              variant={variant}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ProductPackageCard;

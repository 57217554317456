export const productDetails = [
  //super troop
  {
    handle: "super-troop",
    title: "Detail",
    description:
      "Recalibrate and restore your body with super troop. Crafted to enhance focus and energy, while promoting overall immunity, health and balance.",
  },
  {
    handle: "super-troop",
    title: "How to take it",
    description: `Each bottle contains 60 gummies.
  Take 1-3 servings per day.
  Best results obtained with long-term use.`,
  },
  {
    handle: "super-troop",
    title: "Subscription benefits",
    description:
      "Free shipping within the continental US. 30-day money back gurantee. Pause anytime.",
  },

  //lions mane
  {
    handle: "lions-mane",
    title: "Detail",
    description: `Your daily brain boost gummy. Give your brain some love first thing in the morning and feel the benefits all day.`,
  },
  {
    handle: "lions-mane",
    title: "How to take it",
    description: `Each bottle contains 60 gummies.
    Take 1-3 servings per day.
    Best results obtained with long-term use.`,
  },
  {
    handle: "lions-mane",
    title: "Subscription benefits",
    description:
      "Free shipping within the continental US. 30-day money back gurantee. Pause anytime.",
  },

  //reishi
  {
    handle: "reishi",
    title: "Detail",
    description: `Stay calm, cool and collected with reishi at the end of your day.`,
  },
  {
    handle: "reishi",
    title: "How to take it",
    description: `Each bottle contains 60 gummies.
  Take 1-3 servings per day.
  Best results obtained with long-term use.`,
  },
  {
    handle: "reishi",
    title: "Subscription benefits",
    description:
      "Free shipping within the continental US. 30-day money back gurantee. Pause anytime.",
  },
];

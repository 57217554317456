/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from "react";
import * as s from "./QuestionWrapper.module.scss";
import Question from "../../atoms/Question/Question";
import { questions } from "../../../constants/questions";
import green from "../../../images/greenvector.png";
import bubble from "../../../images/greenbubble.png";
import smallbubble from "../../../images/smallbubble.png";
import { navigate } from "gatsby-link";
import classNames from "classnames";
import { Link } from "gatsby";

const cn = classNames.bind(s);

interface IQuestions {
  question: string;
  answer: string;
  link?: string;
  linkTo?: string;
}
[];

const QuestionWrapper = ({ productsPage }: { productsPage?: boolean }) => {
  const [questions1, setQuestions1] = useState<IQuestions[]>([]);

  useEffect(() => {
    productsPage
      ? setQuestions1(questions.slice(0, 5))
      : setQuestions1(questions);
  }, [productsPage]);

  return (
    <div className={s.container} id="faq">
      <div className={s.wrapper}>
        {/* <div className={s.evergreen}>
          <img src={green} alt="leaf" /> 
        </div> */}
        <div className={s.textWrapper}>
          <p className={s.title}>Transparency is key</p>
          <p className={s.text}>
            Have questions about our products? Our team of experts have answers.
          </p>
          <div className={s.questionsWrapper}>
            {questions1.map((q) => (
              <Question
                question={q.question}
                answer={q.answer}
                key={q.question}
                link={q.link}
                linkTo={q.linkTo}
              />
            ))}
          </div>
          {!productsPage ? (
            <>
              <p className={s.moreQuestions}>
                And if you’ve read this far… 10 points to Gryffindor!
              </p>
              <p
                className={cn(s.faq)}
                onClick={() => {
                  navigate("/blog/#blog");
                  window.scrollTo(0, 0);
                }}
              >
                To learn more, click here!
              </p>
            </>
          ) : (
            <>
              <p className={s.moreQuestions}>More questions?</p>
              {/* <p
                className={s.faq}
                onClick={() => {
                  navigate("/blog/#faq");
                  window.scrollTo(0, 0);
                }}
               >
                FAQ?
              </p> */}
              <Link className={s.faq} to="/blog/#faq">
                {" "}
                FAQ?
              </Link>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default QuestionWrapper;

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from "react";
import * as s from "./Review.module.scss";
import classNames from "classnames";
import logo from "../../../images/logo1.png";
import likeActive from "../../../images/likeInactive.svg";
import likeInactive from "../../../images/likeActive.svg";
import dislikeActive from "../../../images/dislikeInactive.svg";
import dislikeInactive from "../../../images/dislikeActive.svg";
import Rating from "../Rating/Rating";
import LikeButton from "../LikeButton/LikeButton";
import { voteOnReview } from "../../../constants/yotpoReviews";

const cn = classNames.bind(s);

interface IReview {
  text?: string;
  name: string;
  country: string;
  rating?: number;
  qa?: boolean;
  answer?: string;
  reviewId?: number;
  title?: string;
  createdAt?: string;
  productTitle?: string;
  initialLikeCount: number;
  initialDislikeCount: number;
}
const Review = ({
  text,
  reviewId,
  name,
  country,
  rating,
  qa,
  answer,
  title,
  createdAt,
  productTitle,
  initialLikeCount,
  initialDislikeCount,
}: IReview) => {
  const [liked, setLiked] = useState(false);
  const [disliked, setDisliked] = useState(false);

  const [upVotes, setUpVotes] = useState(initialLikeCount);
  const [downVotes, setDownVotes] = useState(initialDislikeCount);

  const downVote = async () => {
    let newUpVotes = upVotes;
    let newDownVotes = downVotes;
    let newLiked = liked;
    let newDisliked = disliked;

    if (!disliked) {
      // if it wasn't already disliked
      setDisliked(true);
      setDownVotes(downVotes + 1);
      newDownVotes = downVotes + 1;
      newDisliked = true;
      if (liked) {
        // if it was liked, but not disliked
        setLiked(false);
        setUpVotes(upVotes - 1);
        newUpVotes = upVotes - 1;
        newLiked = false;
        newDisliked = true;
      }
    } else if (disliked) {
      // if it was disliked before
      setDisliked(false);
      setDownVotes(downVotes - 1);
      newDownVotes = downVotes - 1;
      newDisliked = false;
    }

    const result = await voteOnReview(
      reviewId?.toString() || "",
      "down",
      false
    );

    if (result.status.code === 200)
      updateReviewInStorage({
        downVotes: newDownVotes,
        upVotes: newUpVotes,
        liked: newLiked,
        disliked: newDisliked,
      });
  };

  const upVote = async () => {
    let newUpVotes = upVotes;
    let newDownVotes = downVotes;
    let newLiked = liked;
    let newDisliked = disliked;
    if (!liked) {
      // if it wasn't already liked
      setLiked(true);
      setUpVotes(upVotes + 1);
      newUpVotes = upVotes + 1;
      newLiked = true;
      if (disliked) {
        // if it was disliked, but not liked
        setDisliked(false);
        setDownVotes(downVotes - 1);
        newDownVotes = downVotes - 1;
        newLiked = true;
        newDisliked = false;
      }
    } else if (liked) {
      // if it was liked before
      setLiked(false);
      setUpVotes(upVotes - 1);
      newUpVotes = upVotes - 1;
      newLiked = false;
    }

    const result = await voteOnReview(reviewId?.toString() || "", "up", false);

    if (result.status.code === 200)
      updateReviewInStorage({
        downVotes: newDownVotes,
        upVotes: newUpVotes,
        liked: newLiked,
        disliked: newDisliked,
      });
  };

  const findReviewInStorage = (index = false) => {
    const reviewsFromStorage = localStorage.getItem("productReviews");
    if (productTitle && reviewsFromStorage) {
      const parsedReviews = JSON.parse(reviewsFromStorage);
      if (!parsedReviews[productTitle]) return;
      if (index) {
        let reviewIndex = -1;
        //find review
        parsedReviews[productTitle].forEach((item: any, i: number) => {
          if (item.id === reviewId) reviewIndex = i;
        });
        return reviewIndex;
      }
      const storageReview = parsedReviews[productTitle].filter(
        (item: any) => item.id === reviewId
      );
      if (storageReview.length) return storageReview[0];
    }
    return null;
  };

  const updateReviewInStorage = ({
    liked,
    disliked,
    downVotes,
    upVotes,
  }: any) => {
    const reviewsFromStorage = localStorage.getItem("productReviews");
    const parsedReviews = JSON.parse(reviewsFromStorage || "");

    const index = findReviewInStorage(true);
    if (!index || index === -1 || !productTitle) return;
    // update in storage
    parsedReviews[productTitle][index].disliked = disliked;
    parsedReviews[productTitle][index].liked = liked;

    parsedReviews[productTitle][index].votes_down = downVotes;
    parsedReviews[productTitle][index].votes_up = upVotes;

    localStorage.setItem("productReviews", JSON.stringify(parsedReviews));
  };

  useEffect(() => {
    // populate from local storage
    const storageReview = findReviewInStorage();
    if (storageReview) {
      setUpVotes(storageReview.votes_up);
      setDownVotes(storageReview.votes_down);
      setLiked(storageReview.liked);
      setDisliked(storageReview.disliked);
    }
  }, []);

  const renderScore = (rating: number | undefined) => {
    return (
      <>
        {rating && (
          <Rating
            precision={0.25}
            averageScore={rating}
            interactive={false}
            width="12px"
            fontSize="12px"
          />
        )}
      </>
    );
  };
  function convertFromStringToDate(responseDate: string) {
    const dateComponents = responseDate.split("T");
    const datePieces = dateComponents[0].split("-");
    return `${datePieces[1]}/${datePieces[2]}/${datePieces[0]}`;
  }

  return (
    <>
      <div
        className={cn(s.container, {
          [s.qaContainer]: qa === true,
        })}
      >
        <div className={s.wrapper}>
          <div className={s.imageWrapper}>
            <p className={s.name}>{name}</p>
            <p className={s.country}>{country}</p>
            <p className={s.votes}>
              Votes:{" "}
              <span className={s.votesBold}>{Number(upVotes + downVotes)}</span>{" "}
            </p>
          </div>

          <div className={s.textWrapper}>
            <div className={s.scoreAndDate}>
              {" "}
              {renderScore(rating)}
              <p>{convertFromStringToDate(createdAt as string)}</p>
            </div>

            <p className={s.title}>
              {" "}
              {(title as string).replace(
                new RegExp("&" + "#" + "x27;", "g"),
                "'"
              )}
            </p>
            <p className={s.text}>
              {(text as string).replace(
                new RegExp("&" + "#" + "x27;", "g"),
                "'"
              )}
            </p>

            {qa && (
              <div>
                <div className={s.vectorqa}></div>
                <div className={s.qaWrapper}>
                  <p className={cn(s.quote, s.quoteqa)}>“</p>
                  <p className={cn(s.text, s.textQa)}>{answer}</p>
                </div>
                <img src={logo} alt="logo" className={s.logo}></img>
              </div>
            )}

            <div className={cn(s.helpfulWrapper, { [s.helpQa]: qa === true })}>
              <p>Was this helpful?</p>
              <div className={s.likeWrapper}>
                {" "}
                <LikeButton
                  buttonText="Yes"
                  votes={upVotes}
                  icon={liked ? likeActive : likeInactive}
                  onClick={() => upVote()}
                />
                <LikeButton
                  buttonText="No"
                  votes={downVotes}
                  icon={disliked ? dislikeActive : dislikeInactive}
                  onClick={() => downVote()}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Review;

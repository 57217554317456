/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from "react";
import * as s from "./Checkbox.module.scss";
import CheckboxEmpty from "../../../images/CheckboxBlank.svg";
import CheckboxChecked from "../../../images/CheckboxChecked.svg";

interface CheckboxProps {
  value?: string;
  check?: boolean;
}

const Checkbox = ({ value, check }: CheckboxProps) => {
  const [htmlValue, setHtmlValue] = useState<string>();

  useEffect(() => {
    if (value && value.includes("USD")) {
      value = value.replace("USD", "$");
      setHtmlValue(value);
    }
  }, [value]);
  //value = value.replace("USD", "$");
  return (
    <div className={s.container}>
      <div className={s.boxContainer}>
        {check ? (
          <img src={CheckboxChecked} alt="checked" />
        ) : (
          <img src={CheckboxEmpty} alt="checkbox" />
        )}
      </div>
      {htmlValue && (
        <p
          className={s.text}
          dangerouslySetInnerHTML={{ __html: htmlValue }}
        ></p>
      )}
    </div>
  );
};

export default Checkbox;

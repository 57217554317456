import classNames from "classnames";
import React from "react";
import * as s from "./NutritionFact.module.scss";

const cn = classNames.bind(s);

interface NutritionFactProps {
  fact?: string;
  quantity?: string;
  daily?: string;
  optional?: string;
}

const NutritionFact = ({
  fact,
  quantity,
  daily,
  optional,
}: NutritionFactProps) => {
  return (
    <div className={s.container}>
      <div className={s.textDiv}>
        <p className={optional === "1" ? s.textic : s.text}>{fact}</p>
      </div>
      <div className={s.textWrapper}>
        <div className={s.wrapper}>
          <p className={s.text}>{quantity}</p>
        </div>
        <div className={s.dailyWrapper}>
          <p className={cn(s.text)}>{daily}</p>
        </div>
      </div>
    </div>
  );
};

export default NutritionFact;

import React, { useEffect, useState } from "react";
import { useGetAllShopifyProducts } from "../../../g/queries/get-all-shopify-products";
import { SingleProductCard } from "../../molecules/SingleProductCard/SingleProductCard";
import classNames from "classnames";
import * as s from "./ProductsHome.module.scss";
import { getDescription } from "../../../helpers/descriptionHelper";

const cn = classNames.bind(s);

interface ProductsHomeProps {
  filter?: Product;
  page?: "container" | "single" | "catalog";
  cardOrientation?: "horizontal" | "vertical";
}

const ProductsHome = ({
  filter,
  page = "container",
  cardOrientation = "horizontal",
}: ProductsHomeProps) => {
  const { allShopifyProduct } = useGetAllShopifyProducts();
  const filterProd = allShopifyProduct.edges.filter(
    (p: ProductNode) => p?.node?.productType === "Gummies"
  );
  const [products, setProducts] = useState(filterProd.slice(0, 3));
  const [maxWidth, setMaxWidth] = useState(0);

  useEffect(() => {
    if (filter) {
      setProducts(
        products.filter((p: ProductNode) => p.node.handle !== filter.handle)
      );
    } else {
      setProducts([
        ...products.slice(0, products.length - 2),
        products[products.length - 1],
        products[products.length - 2],
      ]);

      // find height of tallest

      const width = Math.max(
        ...products.map(
          (item: any) => getDescription(item.node.description, 0).length
        )
      );
      setMaxWidth(width);
    }
  }, []);

  return (
    <div className={cn(s.wrapper)}>
      {page !== "container" ? <p className={s.text}>OTHER PRODUCTS</p> : null}
      <div
        className={cn(s.container, {
          [s.containerContainer]: page === "container",
        })}
      >
        {products ? (
          products.map((el: ProductNode, index: number) => {
            return (
              <SingleProductCard
                key={el.node.id}
                product={el.node}
                page={page}
                orientation={
                  page === "container"
                    ? "center"
                    : index === 0
                    ? "left"
                    : "right"
                }
              />
            );
          })
        ) : (
          <p>No Products found!</p>
        )}
      </div>
    </div>
  );
};

export { ProductsHome };

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions */
import React, { useState } from "react";
import * as s from "./Question.module.scss";
import GreenPlus from "../../../images/GreenPlus.svg";
import GreenMinus from "../../../images/GreenMinus.svg";
import GrayPlus from "../../../images/GrayPlus.png";
import GrayMinus from "../../../images/grayminus.png";

interface IResearch {
  title: string;
  link?: string;
}

interface QuestionProps {
  question: string;
  answer?: string;
  component?: string;
  researchList?: IResearch[];
  list?: boolean;
  link?: string;
  linkTo?: string;
}

const Question = ({
  answer,
  question,
  component,
  researchList,
  list,
  link,
  linkTo,
}: QuestionProps) => {
  const [show, setShow] = useState(false);

  const handleClick = () => {
    setShow(!show);
  };

  return (
    <div className={s.container}>
      <div
        className={
          component === "benefits" ? s.titleContainerBenefits : s.titleContainer
        }
        onClick={handleClick}
      >
        <p
          className={component === "benefits" ? s.titleBenefits : s.title}
          onClick={handleClick}
        >
          {question}
        </p>
        <div className={s.iconWrapper} onClick={handleClick}>
          {show ? (
            component === "benefits" ? (
              <img src={GrayMinus} alt="minus"></img>
            ) : (
              <img src={GreenMinus} alt="minus" />
            )
          ) : component === "benefits" ? (
            <img src={GrayPlus} alt="plus"></img>
          ) : (
            <img src={GreenPlus} alt="plus" />
          )}
        </div>
      </div>
      {show && !list && (
        <>
          <div
            className={s.answer}
            dangerouslySetInnerHTML={{ __html: answer as string }}
          ></div>
          <a className={s.answer} href={linkTo}>
            {link}
          </a>
        </>
      )}
      {show && list
        ? researchList?.map((r, index) => (
            <p
              onClick={() => window.open(r.link)}
              className={s.link}
              key={index}
            >
              {r && r.title}
            </p>
          ))
        : null}
    </div>
  );
};

export default Question;

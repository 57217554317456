/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions */

import React, { useState, useRef, useEffect } from "react";
import StarIcon from "@mui/icons-material/Star";
import StarBorderIcon from "@mui/icons-material/StarBorder";

const Rating = ({
  precision = 1,
  totalStars = 5,
  emptyIcon = StarBorderIcon,
  filledIcon = StarIcon,
  averageScore,
  interactive,
  width,
  fontSize,
}: {
  precision?: number;
  totalStars?: number;
  emptyIcon?: any;
  filledIcon?: any;
  averageScore: number;
  interactive?: boolean;
  width?: string;
  fontSize: string;
}) => {
  const [activeStar, setActiveStar] = useState<number>(averageScore);
  useEffect(() => {
    setActiveStar(averageScore);
  }, [averageScore]);

  const [hoverActiveStar, setHoverActiveStar] = useState(-1);
  const [isHovered, setIsHovered] = useState(false);
  const ratingContainerRef = useRef(null);
  const [widthStar, setWidth] = useState(width);

  const calculateRating = (e: any) => {
    const { width, left } = (
      ratingContainerRef.current as any
    ).getBoundingClientRect();
    const percent = (e.clientX - left) / width;
    const numberInStars = percent * totalStars;
    const nearestNumber =
      Math.round((numberInStars + precision / 2) / precision) * precision;

    return Number(
      nearestNumber.toFixed(precision.toString().split(".")[1]?.length || 0)
    );
  };

  const handleClick = (e: any) => {
    if (interactive) {
      setIsHovered(false);
      setActiveStar(calculateRating(e));
    }
  };

  const handleMouseMove = (e: any) => {
    if (interactive) {
      setIsHovered(true);
      setHoverActiveStar(calculateRating(e));
    }
  };

  const handleMouseLeave = (e: any) => {
    if (interactive) {
      setHoverActiveStar(-1); // Reset to default state
      setIsHovered(false);
    }
  };
  const EmptyIcon = emptyIcon;
  const FilledIcon = filledIcon;

  return (
    <div
      style={{
        display: "inline-flex",
        position: "relative",
        cursor: "pointer",
        textAlign: "left",
      }}
      onClick={handleClick}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      ref={ratingContainerRef}
    >
      {[...new Array(totalStars)].map((arr, index) => {
        const activeState = isHovered ? hoverActiveStar : activeStar;

        const showEmptyIcon = activeState === -1 || activeState < index + 1;

        const isActiveRating = activeState !== 1;
        const isRatingWithPrecision = activeState % 1 !== 0;
        const isRatingEqualToIndex = Math.ceil(activeState) === index + 1;
        const showRatingWithPrecision =
          isActiveRating && isRatingWithPrecision && isRatingEqualToIndex;

        return (
          <div
            style={{
              cursor: "pointer",
              position: "relative",
            }}
            key={index}
          >
            <div
              style={{
                width: showRatingWithPrecision
                  ? `${(activeState % 1) * 100}%`
                  : "0%",
                overflow: "hidden",
                position: "absolute",
              }}
            >
              <FilledIcon
                sx={{ fill: "#F8C51B", width: widthStar, fontSize: fontSize }}
              />
            </div>
            {/*Note here */}
            <div
              style={{
                color: showEmptyIcon ? "#D5DADD" : "inherit",
              }}
            >
              {showEmptyIcon ? (
                <FilledIcon
                  sx={{ fill: "#D5DADD", width: widthStar, fontSize: fontSize }}
                />
              ) : (
                <FilledIcon
                  sx={{ fill: "#F8C51B", width: widthStar, fontSize: fontSize }}
                />
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Rating;

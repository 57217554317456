// extracted by mini-css-extract-plugin
export var arrowContainer = "Reviews&QA-module--arrowContainer--6O46M";
export var arrowNotActive = "Reviews&QA-module--arrowNotActive--joVm5";
export var averageMain = "Reviews&QA-module--averageMain--zFq9l";
export var averageTitle = "Reviews&QA-module--averageTitle--91Q0S";
export var barContainer = "Reviews&QA-module--barContainer--S6+TC";
export var barScore = "Reviews&QA-module--barScore--ORDeQ";
export var barText = "Reviews&QA-module--barText--hVyAH";
export var barTitle = "Reviews&QA-module--barTitle--4vcY-";
export var card = "Reviews&QA-module--card--bwUiO";
export var cardSelected = "Reviews&QA-module--card-selected--YI20N";
export var cardsWrapper = "Reviews&QA-module--cards-wrapper--bol66";
export var icon = "Reviews&QA-module--icon--SsTF+";
export var mainContainer = "Reviews&QA-module--mainContainer--5FxIq";
export var mainWrapper = "Reviews&QA-module--main-wrapper--1mxrV";
export var optionItem = "Reviews&QA-module--optionItem--4GO0j";
export var pageNumber = "Reviews&QA-module--pageNumber--uUWLu";
export var pageNumberActive = "Reviews&QA-module--pageNumberActive--dmNr6";
export var pageNumberContainer = "Reviews&QA-module--pageNumberContainer--OWiy7";
export var paginationContainer = "Reviews&QA-module--paginationContainer--TxtzO";
export var paginationCount = "Reviews&QA-module--paginationCount--cU5m6";
export var reviewsWrapper = "Reviews&QA-module--reviews-wrapper--uETX4";
export var scoreWrapper = "Reviews&QA-module--score-wrapper--wruGf";
export var sortContainer = "Reviews&QA-module--sortContainer--4ODuF";
export var sortPagContainer = "Reviews&QA-module--sortPagContainer--Xe6zb";
export var sortSelect = "Reviews&QA-module--sortSelect--7wiqN";
export var sortText = "Reviews&QA-module--sortText--GSu2U";
export var spinnerContainer = "Reviews&QA-module--spinnerContainer--tsTbR";
export var title = "Reviews&QA-module--title--JJ+VP";
export var writeReviewBtn = "Reviews&QA-module--writeReviewBtn--xxO5G";
// extracted by mini-css-extract-plugin
export var buttonContainer = "ProductPackageCard-module--buttonContainer--esXUJ";
export var checkboxContainer = "ProductPackageCard-module--checkboxContainer--MC0eT";
export var checkboxText = "ProductPackageCard-module--checkboxText--N-h7K";
export var container = "ProductPackageCard-module--container--ynX+t";
export var description = "ProductPackageCard-module--description--PdxnV";
export var descriptionContainer = "ProductPackageCard-module--descriptionContainer--4b664";
export var imageContainer = "ProductPackageCard-module--imageContainer--aiuof";
export var regularPrice = "ProductPackageCard-module--regularPrice--VQu1k";
export var salePrice = "ProductPackageCard-module--salePrice--bwzps";
export var subscribeText = "ProductPackageCard-module--subscribeText--YXfIJ";
export var textContainer = "ProductPackageCard-module--textContainer--jn89Z";
export var title = "ProductPackageCard-module--title--Fa6LI";
export var titleStrong = "ProductPackageCard-module--titleStrong--YHP2L";
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useEffect, useState } from "react";
import * as s from "./Reviews&QA.module.scss";
import classNames from "classnames";
import Review from "../../atoms/Review/Review";
import { getAverageScoreForAllProducts } from "../../../helpers/getAverageScore";
import { getAverageScore } from "../../../helpers/getAverageScore";
import AddReviewPopup from "../../molecules/AddReviewPopup/AddReviewPopup";
import Rating from "../../atoms/Rating/Rating";
import { getStarsDist } from "../../../constants/yotpoReviews";
import ScoreBar from "../../atoms/ScoreBar/ScoreBar";
import arrowLeft from "../../../images/PagginationArrowLeft.svg";
import arrowRight from "../../../images/PagginationArrowRight.svg";
import PulseLoader from "react-spinners/PulseLoader";

import { MenuItem, Select, SelectChangeEvent } from "@mui/material";

const cn = classNames.bind(s);

interface ISingleReview {
  content?: string;
  custom_fields?: null;
  created_at: string;
  deleted?: boolean;
  verified_buyer: boolean;
  id?: number;
  product_id: number;
  user: {
    display_name: string;
    is_social_connected: number;
    social_image?: null;
    user_id: number;
    user_type: string;
  };
  score: number;
  sentiment?: number;
  source_review_id?: null;
  title?: string;
  votes_down?: number;
  votes_up: number;
}

interface IProps {
  reviews?: ISingleReview[];
  product: Product;
}

const MenuProps = {
  PaperProps: {
    style: {
      boxShadow:
        "0px 0px 1px rgba(26, 32, 36, 0.32),0px 8px 16px rgba(91, 104, 113, 0.24)",
      borderRadius: "4px",
      marginTop: "8px",
      width: "188px",
    },
  },
};

const ReviewsPage = ({ reviews, product }: IProps) => {
  const [popUp, showPopUp] = useState(false);
  const [averageScore, setAverageScore] = useState();
  const [averageScoreProduct, setAverageScoreProduct] = useState();
  const [numberOfReviews, setNumberOfReviews] = useState<number | undefined>(
    reviews?.length
  );
  const [starDistribution, setStarDistribution] = useState();

  const [filteredReviews, setFilteredReviews] = useState(reviews);

  const [starNumber, setStarNumber] = useState<number>();
  const [sortValues, setSortValues] = useState<string>("");
  const [page, setPage] = useState<number>(1);
  const [numberOfPages, setNumberOfPages] = useState<number>();
  const [displayReviews, setDisplayReviews] = useState<ISingleReview[]>();
  const [startIndexReviews, setStartIndexReviews] = useState<number>();
  const [lastIndexReviews, setLastIndexReviews] = useState<number>();
  const [popUpInfo, setPopUpInfo] = useState<Product>();

  const getScore = async (yotpoAppKey: string) => {
    const score = await getAverageScoreForAllProducts(yotpoAppKey);
    setAverageScore(score);
  };

  const getAverageScoreProduct = async (
    yotpoAppKey: string,
    productId: string
  ) => {
    const score = await getAverageScore(yotpoAppKey, productId);
    setAverageScoreProduct(score?.bottomline.average_score);
    setNumberOfReviews(score?.bottomline.total_reviews);
  };

  const getStarDistributation = async (
    yotpoAppKey: string,
    productId: string
  ) => {
    const allReviews = await getStarsDist(yotpoAppKey, productId);
    setStarDistribution(allReviews.star_distribution);
  };

  const getPaginationReviews = () => {
    const startIndex = page * 6 - 6;
    const lastIndex = startIndex + 6;
    if (filteredReviews && filteredReviews?.length) {
      const paginationReviews = filteredReviews.slice(startIndex, lastIndex);
      setDisplayReviews(paginationReviews);
    }
  };

  const getFilterReviwesByRating = () => {
    const filterReviewsByRatings = reviews?.filter(
      (review) => review.score === starNumber
    );
    setFilteredReviews(filterReviewsByRatings);
  };

  const getSortedReviews = () => {
    if (reviews && reviews.length) {
      const sortedReviews = [...reviews] as ISingleReview[];
      if (sortValues === "most-helpful") {
        sortedReviews?.sort((a, b) => b?.votes_up - a?.votes_up);
      } else if (sortValues === "highest-to-lowest") {
        sortedReviews?.sort((a, b) => b?.score - a?.score);
      } else if (sortValues === "lowest-to-highest") {
        sortedReviews?.sort((a, b) => a?.score - b?.score);
      } else if (sortValues === "most-recent") {
        sortedReviews?.sort(
          (b, a) =>
            new Date(a?.created_at).getTime() -
            new Date(b?.created_at).getTime()
        );
      }
      setFilteredReviews(sortedReviews);
    }
  };

  const handlePopUp = () => {
    showPopUp(false);
  };
  const handleClick = (product: Product) => {
    showPopUp(true);
    setPopUpInfo(product);
  };
  const handleClickOnStarBar = (num: number) => {
    setStarNumber(num);
  };

  const handleChange = (e: SelectChangeEvent) => {
    setSortValues(e.target.value);
  };

  const handleLeftArrowClick = () => {
    if (page !== 1) {
      setPage(page - 1);
    }
  };
  const handleRightArrowClick = () => {
    if (page !== numberOfPages) {
      setPage(page + 1);
    }
  };

  useEffect(() => {
    const yotpoKey = process.env.GATSBY_YOTPO_APP_KEY;
    if (product && product.shopifyId) {
      getAverageScoreProduct(yotpoKey as string, product.shopifyId.toString());
      getStarDistributation(yotpoKey as string, product?.shopifyId.toString());
    }
    if (reviews && reviews.length) {
      const reviewsNumber = reviews?.length;
      setNumberOfReviews(reviewsNumber);
    }
  }, [product]);

  useEffect(() => {
    if (numberOfReviews) {
      const numOfPages = Math.ceil(numberOfReviews / 6);
      setNumberOfPages(numOfPages);
    }
  }, [numberOfReviews]);

  useEffect(() => {
    getPaginationReviews();
    const numberOfFiltredReviews = filteredReviews?.length;
    setNumberOfReviews(numberOfFiltredReviews);
  }, [page, filteredReviews]);

  useEffect(() => {
    const startIndex = page * 6 - 6;
    const lastIndex = startIndex + 6;
    setStartIndexReviews(startIndex + 1);
    if (numberOfReviews && lastIndex > numberOfReviews)
      setLastIndexReviews(numberOfReviews);
    else setLastIndexReviews(lastIndex);
  }, [displayReviews]);

  useEffect(() => {
    const yotpoKey = process.env.GATSBY_YOTPO_APP_KEY;
    getScore(yotpoKey as string);
    if (reviews && reviews.length) {
      const reviewsNumber = reviews?.length;
      setNumberOfReviews(reviewsNumber);
      setDisplayReviews(reviews.slice(0, 6));
    }
  }, []);

  useEffect(() => {
    getFilterReviwesByRating();
    setPage(1);
  }, [starNumber]);

  useEffect(() => {
    getSortedReviews();
    setPage(1);
  }, [sortValues]);

  return (
    <div className={s.mainWrapper} id="reviews">
      <div className={s.cardsWrapper}>
        <p className={s.title}>
          Mush <span> love</span>
        </p>
      </div>

      <div className={s.mainContainer}>
        {reviews && reviews.length && starDistribution ? (
          <div className={s.barContainer}>
            <h3 className={s.barTitle}>User reviews ({reviews.length})</h3>
            <p className={s.barText}>Select a row below to filter reviews</p>
            {[...Array(5)].map((el, index) => (
              <ScoreBar
                scoreNumber={5 - index}
                percent={Math.round(
                  (starDistribution?.[5 - index] * 100) / reviews?.length
                )}
                handleClickStar={() =>
                  (starDistribution?.[5 - index] * 100) / reviews?.length
                    ? handleClickOnStarBar(5 - index)
                    : ""
                }
                key={index}
              />
            ))}
          </div>
        ) : (
          <div className={s.spinnerContainer}>
            <PulseLoader color={"#16b76a"} loading={true} />
          </div>
        )}
        <div className={s.averageMain}>
          <button
            className={s.writeReviewBtn}
            onClick={() => handleClick(product)}
          >
            Write a review
          </button>
          {averageScore && (
            <h2 className={s.averageTitle}>
              Average {averageScoreProduct} out of 5{" "}
            </h2>
          )}
          <div className={s.scoreWrapper}>
            {averageScoreProduct && (
              <Rating
                precision={0.25}
                averageScore={averageScoreProduct}
                interactive={false}
                width="40px"
                fontSize="40px"
              />
            )}
          </div>
        </div>
      </div>
      {filteredReviews && (
        <div className={s.sortPagContainer}>
          {startIndexReviews && lastIndexReviews && numberOfReviews && (
            <div className={s.paginationCount}>
              <p>
                {startIndexReviews}-{lastIndexReviews} of {numberOfReviews}{" "}
                reviews
              </p>
            </div>
          )}
          <div className={s.sortContainer}>
            <p className={s.sortText}>Sort by</p>

            <Select
              className={s.sortSelect}
              value={sortValues}
              onChange={handleChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              MenuProps={MenuProps}
            >
              <MenuItem value="" className={s.optionItem}>
                Select
              </MenuItem>
              <MenuItem value="most-helpful" className={s.optionItem}>
                Most helpful
              </MenuItem>
              <MenuItem value="highest-to-lowest" className={s.optionItem}>
                Highest to lowest
              </MenuItem>
              <MenuItem value="lowest-to-highest" className={s.optionItem}>
                Lowest to highest
              </MenuItem>
              <MenuItem value="most-recent" className={s.optionItem}>
                Most recent
              </MenuItem>
            </Select>
          </div>
        </div>
      )}
      <div className={s.reviewsWrapper}>
        {displayReviews ? (
          displayReviews.map((r: ISingleReview) => (
            <Review
              key={r.id}
              reviewId={r.id}
              text={r.content}
              title={r.title}
              name={r.user?.display_name}
              country={"US"}
              rating={r.score}
              createdAt={r.created_at}
              productTitle={product.title}
              initialDislikeCount={r.votes_down!}
              initialLikeCount={r.votes_up!}
            />
          ))
        ) : (
          <div className={s.spinnerContainer}>
            <PulseLoader color={"#16b76a"} loading={true} />
          </div>
        )}
      </div>
      {numberOfPages &&
        numberOfPages !== 1 &&
        filteredReviews &&
        filteredReviews.length && (
          <div className={s.paginationContainer}>
            <div
              className={page !== 1 ? s.arrowContainer : s.arrowNotActive}
              onClick={handleLeftArrowClick}
            >
              <img src={arrowLeft} alt="Arrow Left" />
            </div>
            <div className={s.pageNumberContainer}>
              {numberOfPages &&
                [...Array(numberOfPages)].map((el, index) => (
                  <button
                    className={
                      page === index + 1 ? s.pageNumberActive : s.pageNumber
                    }
                    key={index}
                    onClick={() => setPage(index + 1)}
                  >
                    {index + 1}
                  </button>
                ))}
            </div>
            <div
              className={
                page !== numberOfPages ? s.arrowContainer : s.arrowNotActive
              }
              onClick={handleRightArrowClick}
            >
              {" "}
              <img src={arrowRight} alt="Arrow Right" />
            </div>
          </div>
        )}

      <AddReviewPopup
        product={product}
        visible={popUp}
        handlePopUp={handlePopUp}
      />
    </div>
  );
};

export default ReviewsPage;

// extracted by mini-css-extract-plugin
export var benefitList = "DemonstratedBenefits-module--benefit-list--DDis1";
export var benefitListItem = "DemonstratedBenefits-module--benefit-list-item--8ZX6V";
export var container = "DemonstratedBenefits-module--container--A0TNF";
export var imageContainer = "DemonstratedBenefits-module--image-container--HR7fD";
export var pills = "DemonstratedBenefits-module--pills--nqwF8";
export var pillsBackground = "DemonstratedBenefits-module--pills-background--cHf9d";
export var statement = "DemonstratedBenefits-module--statement--K+xhS";
export var text = "DemonstratedBenefits-module--text--MpC+r";
export var textContainer = "DemonstratedBenefits-module--text-container--SZIFW";
export var title = "DemonstratedBenefits-module--title--krYJC";
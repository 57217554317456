import React, { useEffect, useState } from "react";
import ShroomsCard from "../ShroomsCard/ShroomsCard";
import * as s from "./MushroomComponent.module.scss";
import green from "../../../images/greencircle.png";
import blue from "../../../images/bluecircle.png";
import reishi from "../../../images/reishis.png";
import lions from "../../../images/Group 299.png";
import { Link } from "gatsby";

interface FullProductProps {
  product: Product;
}

const MushroomComponent = ({ product }: FullProductProps) => {
  const [link, setLink] = useState("");

  useEffect(() => {
    if (product?.handle === "lions-mane")
      setLink("/meet-your-shroom-single/#lionsmane");
    else setLink("/meet-your-shroom-single/#reishi");
  }, [product]);

  return (
    <div className={s.container}>
      <div className={s.wrapper}>
        <p className={s.title}>Let’s explore world of mushrooms together</p>
        <p className={s.description}>
          Mushrooms, but make it fun. Welcome to a whole new way to chew your
          mushrooms.
        </p>
        <Link to={link} className={s.link}>
          Meet your mushrooms -&gt;
        </Link>
      </div>

      <div className={s.shroomWrapper}>
        {product?.handle === "lions-mane" ? (
          <>
            <img src={green} alt="" className={s.image}></img>
            <ShroomsCard
              title={product?.title}
              description={product?.description}
              page="mushroom"
              image={lions}
              type="lionsmane"
            />
          </>
        ) : (
          <>
            <img src={blue} alt="" className={s.image}></img>
            <ShroomsCard
              title={product?.title}
              description={product?.description}
              page="mushroom"
              image={reishi}
              type="reishi"
            />
          </>
        )}
      </div>
    </div>
  );
};

export default MushroomComponent;

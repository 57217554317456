/* eslint-disable jsx-a11y/click-events-have-key-events, 
jsx-a11y/no-noninteractive-element-interactions, 
jsx-a11y/no-static-element-interactions */

import React, { useState } from "react";
import { ProductImage } from "../../atoms/ProductImage/ProductImage";
import * as s from "./ProductImageSlider.module.scss";
import arrowR from "../../../images/Chevron right.svg";
import classNames from "classnames";

const cn = classNames.bind(s);

const ProductImageSlider = ({
  images,
}: {
  images: TroopState.ImagesProps[];
}) => {
  const [activeImage, setActiveImage] = useState(images[2]); //change this once we delete images from shopify
  const [activeIndex, setActiveIndex] = useState(1);

  const handleClick = (activeImage: any, direction: string) => {
    const num = images.indexOf(activeImage);
    if (num + 1 < images.length && direction === "right") {
      setActiveImage(images[num + 1]);
      setActiveIndex(num + 1);
    } else if (num + 1 === images.length && direction === "right") {
      setActiveImage(images[2]);
      setActiveIndex(2);
    }
    if (num - 1 !== 1 && num - 1 <= images.length && direction === "left") {
      setActiveImage(images[num - 1]);
      setActiveIndex(num - 1);
    } else if (num - 1 === 1 && direction === "left") {
      setActiveImage(images[images.length - 1]);
      setActiveIndex(images.length - 1);
    }
  };

  return (
    <div className={s.mainWrapper}>
      <div className={s.mainImageWrapper}>
        <img
          src={arrowR}
          className={s.leftArrow}
          alt="left"
          onClick={() => handleClick(activeImage, "left")}
        ></img>{" "}
        <ProductImage
          imageUrl={activeImage?.originalSrc}
          alt="slika"
          page="singleProduct"
        />
        <img
          src={arrowR}
          className={s.rightArrow}
          onClick={() => handleClick(activeImage, "right")}
          alt="right"
        ></img>{" "}
      </div>

      <div className={s.imagesWrapper}>
        {images.map((image: TroopState.ImagesProps, index: number) => {
          if (index !== 0 && index !== 1)
            return (
              <div
                className={cn({ [s.singleImage]: index === activeIndex })}
                onClick={() => {
                  setActiveImage(images[index]);
                  setActiveIndex(index);
                }}
              >
                <ProductImage
                  imageUrl={image?.originalSrc}
                  alt="slika"
                  page="imageSlider"
                />
              </div>
            );
        })}
      </div>
    </div>
  );
};

export default ProductImageSlider;

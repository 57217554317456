// extracted by mini-css-extract-plugin
export var avatar = "Review-module--avatar--Zawiv";
export var avatarWrapper = "Review-module--avatar-wrapper---IryQ";
export var container = "Review-module--container--UDui-";
export var country = "Review-module--country--O1LuI";
export var dislike = "Review-module--dislike--CLgd5";
export var helpQa = "Review-module--help-qa--cb8DH";
export var helpfulWrapper = "Review-module--helpful-wrapper--YlI+M";
export var imageWrapper = "Review-module--image-wrapper--dG6-m";
export var job = "Review-module--job--Jfy0L";
export var likeWrapper = "Review-module--like-wrapper--F+uhn";
export var logo = "Review-module--logo--XRiDN";
export var name = "Review-module--name--rIaow";
export var profile = "Review-module--profile--7wZPD";
export var qaContainer = "Review-module--qa-container--FYD7M";
export var qaWrapper = "Review-module--qa-wrapper--2CvYu";
export var quote = "Review-module--quote--WF8N-";
export var quoteqa = "Review-module--quoteqa--lBzQV";
export var scoreAndDate = "Review-module--score-and-date--4B8H3";
export var text = "Review-module--text--HfdkC";
export var textQa = "Review-module--text-qa--9nn8V";
export var textWrapper = "Review-module--text-wrapper--Gdnxg";
export var title = "Review-module--title--glWSC";
export var vector = "Review-module--vector--SGgPE";
export var vectorqa = "Review-module--vectorqa--0g8TS";
export var votes = "Review-module--votes--gjtH0";
export var votesBold = "Review-module--votesBold--zaPWT";
export var wrapper = "Review-module--wrapper--yrl7e";
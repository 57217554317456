// extracted by mini-css-extract-plugin
export var buttonsWrapper = "ProductInfo-module--buttons-wrapper--CQQ1p";
export var checkboxWrapper = "ProductInfo-module--checkbox-wrapper--z1wWt";
export var container = "ProductInfo-module--container--L-47Y";
export var flavour = "ProductInfo-module--flavour--Vcwrb";
export var flex = "ProductInfo-module--flex--UgTHT";
export var imageWrapper = "ProductInfo-module--image-wrapper--HRTBa";
export var productInfo = "ProductInfo-module--product-info--vYjAg";
export var productSubtitle = "ProductInfo-module--product-subtitle--c6dug";
export var productTitle = "ProductInfo-module--product-title--JXEA7";
export var productVariantContainer = "ProductInfo-module--product-variant-container--e73KA";
export var quantityWraper = "ProductInfo-module--quantity-wraper--ldz00";
export var reviewText = "ProductInfo-module--review-text--cgbk0";
export var scoreWrapper = "ProductInfo-module--score-wrapper--0y5Kk";
export var subscribe = "ProductInfo-module--subscribe--+GjjU";
export var subscribeWrapper = "ProductInfo-module--subscribe-wrapper--sSjMw";
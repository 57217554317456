/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions */

import React, { useEffect, useState } from "react";
import * as s from "./ProductInfo.module.scss";
import { AddToCartButton } from "../../atoms/AddToCartButton/AddToCartButton";
import Checkbox from "../../atoms/Checkbox/Checkbox";
import {
  decodeBase64,
  getProductReviews,
} from "../../../constants/yotpoReviews";
import { getDescription } from "../../../helpers/descriptionHelper";
import { useLazyQuery } from "@apollo/client";
import { GET_PLAN_BY_PRODUCT_ID } from "../../../g/queries/get-plans";
import { get } from "lodash";
import ProductImageSlider from "../ProductImageSlider/ProductImageSlider";
import Quantity from "../../atoms/Quantity/Quantity";
import Expander from "../../atoms/Expander/Expander";
import { productDetails } from "../../../constants/productDetails";
import { getCheckbox } from "./helper";
import calendar from "../../../images/calendar.svg";
import { getAverageScore } from "../../../helpers/getAverageScore";
import Rating from "../../atoms/Rating/Rating";
import AddReviewPopup from "../AddReviewPopup/AddReviewPopup";
import { useWindowSize } from "../../../hooks";
import { number } from "../../atoms/ScoreBar/ScoreBar.module.scss";

interface ProductInfoProps {
  product: Product;
  reviews: any;
}

const ProductInfo = ({ product, reviews }: ProductInfoProps) => {
  const width = useWindowSize().width;
  const [variant, setVariant] = useState(product?.variants?.[0]);
  const [description, setDescription] = useState("");
  const [flavour, setFlavour] = useState<string[]>([]);
  const [benefit, setBenefit] = useState<string>("");
  const [quantity, setQuantity] = useState(1);
  const [averageScore, setAverageScore] = useState();
  const [popUpInfo, setPopUpInfo] = useState<Product>();
  const [showPopUp, setShowPopup] = useState(false);
  const [numberOfReviews, setNumberOfReviews] = useState();
  const [plans, setPlans] = useState<IPlans>({
    id: "",
    name: "",
    priceAdjustments: [],
  });
  const [planId, setPlanId] = useState<string | null>(null);
  const [price, setPrice] = useState<number>(0);
  const availableForSale = product?.availableForSale;
  const [title, setTitle] = useState<string>();
  const [discount, setDiscount] = useState(1);
  const checkboxOptions = getCheckbox(
    price,
    variant?.priceV2?.currencyCode,
    discount
  );
  const [check, setCheck] = useState<{
    id: string;
    price: number;
    title: string;
  }>(checkboxOptions[0]);
  const [adjustedPrice, setAdjustedPrice] = useState(0);

  const [sellingPlans] = useLazyQuery(GET_PLAN_BY_PRODUCT_ID, {
    fetchPolicy: "network-only",
    onCompleted: ({ product }) => {
      if (
        product?.sellingPlanGroups?.edges &&
        product?.sellingPlanGroups?.edges[0]?.node &&
        product?.sellingPlanGroups?.edges[0]?.node?.sellingPlans?.edges &&
        product?.sellingPlanGroups?.edges[0]?.node?.sellingPlans?.edges[0]?.node
      ) {
        const data =
          product?.sellingPlanGroups?.edges[0]?.node?.sellingPlans?.edges[0]
            ?.node;
        const adminId = "gid://shopify/SellingPlan/" + decodeBase64(data?.id);
        setPlans({
          ...data,
          id: adminId,
        });
        const adjustment = get(
          data.priceAdjustments[0],
          "adjustmentValue.adjustmentPercentage"
        );
        setDiscount(adjustment);
        setAdjustedPrice(price - price * (adjustment / 100));
      }
    },
  });

  useEffect(() => {
    const id = "gid://shopify/Product/" + decodeBase64(product.shopifyId || "");
    sellingPlans({
      variables: {
        id,
      },
    });
    setPrice(parseInt(variant?.priceV2?.amount));
    setAdjustedPrice(parseInt(variant?.priceV2?.amount));
  }, [product.shopifyId]);

  useEffect(() => {
    setCheck(checkboxOptions[1]);
    setPlanId(plans.id);
  }, [discount]);

  useEffect(() => {
    const split = product.title.split(" ");
    const last = split.pop();
    const t = split.join(" ") + ` <b>${last}</b>`;
    setTitle(t);
    const desc = getDescription(product.descriptionHtml || "", 2);
    const fl = getDescription(product.description || "", 2, true);
    const benefitTemp = getDescription(product.description || "", 5);
    setFlavour(fl as string[]);
    setDescription(desc as string);
    setBenefit(benefitTemp as string);
  }, []);

  const getScore = async (yotpoAppKey: string, productId: string) => {
    const score = await getAverageScore(yotpoAppKey, productId);
    setAverageScore(score?.bottomline?.average_score);
    setNumberOfReviews(score?.bottomline?.total_reviews);
  };

  const handleClick = (product: Product) => {
    setPopUpInfo(product);
    setShowPopup(true);
  };

  useEffect(() => {
    const yotpoKey = process.env.GATSBY_YOTPO_APP_KEY;
    if (product && product.shopifyId) {
      getScore(yotpoKey as string, product.shopifyId.toString());
    }
  }, [product]);

  const handleChecked = (c: { id: string; price: number; title: string }) => {
    if (c.id === "sub") {
      setPlanId(plans.id);
    } else {
      setPlanId(null);
    }
    setCheck(c);
    if (c.title === checkboxOptions[0].title) setAdjustedPrice(price);
    else setAdjustedPrice(price - price * (discount / 100));
  };

  return (
    <div className={s.container}>
      <div className={s.imageWrapper}>
        <ProductImageSlider images={product?.images} />
      </div>
      <div className={s.productInfo}>
        {title ? (
          <div className={s.flex}>
            <p
              className={s.productTitle}
              dangerouslySetInnerHTML={{ __html: title }}
            ></p>
            <span
              className={s.flavour}
              style={{ color: flavour[0], background: flavour[2] }}
              dangerouslySetInnerHTML={{ __html: flavour[1] }}
            />
          </div>
        ) : null}

        <div className={s.scoreWrapper}>
          {averageScore && (
            <div
              onClick={() =>
                document
                  ?.getElementById("reviews")
                  ?.scrollIntoView({ behavior: "smooth" })
              }
            >
              <Rating
                precision={0.25}
                averageScore={averageScore}
                interactive={false}
                width="20px"
                fontSize="20px"
              />
            </div>
          )}
          <div style={{ display: "flex", gap: "8px" }}>
            <p
              className={s.reviewText}
              onClick={() =>
                document
                  ?.getElementById("reviews")
                  ?.scrollIntoView({ behavior: "smooth" })
              }
            >
              ({numberOfReviews})
            </p>
            <p className={s.reviewText} onClick={() => handleClick(product)}>
              {" "}
              Write a review
            </p>
          </div>
        </div>

        <p
          className={s.productSubtitle}
          dangerouslySetInnerHTML={{ __html: benefit }}
        ></p>

        <div className={s.productVariantContainer}>
          <div className={s.quantityWraper}>
            {" "}
            <Quantity newQuantity={quantity} setNewQuantity={setQuantity} />
          </div>

          {variant && (
            <div className={s.buttonsWrapper}>
              <AddToCartButton
                variant={variant}
                checkedPrice={check.price}
                quantity={quantity}
                planId={planId}
                isBuyNow={false}
                availableForSale={availableForSale}
              />
            </div>
          )}
        </div>
        <div>
          <div
            key={checkboxOptions[0].id}
            onClick={() => handleChecked(checkboxOptions[0])}
            className={s.checkboxWrapper}
          >
            <Checkbox
              value={checkboxOptions[0].title}
              check={check.id === checkboxOptions[0].id}
            />
          </div>
          <div className={s.subscribeWrapper}>
            <img src={calendar} alt="calendar"></img>
            <p className={s.subscribe}>Subscribe & save ({discount}%)</p>
          </div>
          <div
            key={checkboxOptions[1].id}
            onClick={() => handleChecked(checkboxOptions[1])}
            className={s.checkboxWrapper}
          >
            <Checkbox
              value={checkboxOptions[1].title}
              check={check.id === checkboxOptions[1].id}
            />
          </div>
        </div>

        {productDetails.map((prod) => {
          if (product?.handle === prod.handle)
            return (
              <Expander
                key={"expander" + prod.handle + prod.title}
                title={prod.title}
                description={prod?.description}
              />
            );
        })}
      </div>
      <div
        className="rc-widget-injection-parent"
        data-product-id={product.id}
      ></div>
      <AddReviewPopup
        product={product}
        visible={showPopUp}
        handlePopUp={() => setShowPopup(false)}
      />

      <AddToCartButton
        variant={variant}
        checkedPrice={adjustedPrice}
        quantity={quantity}
        planId={planId}
        isBuyNow={false}
        availableForSale={availableForSale}
        isFixed={true}
      />
    </div>
  );
};

export default ProductInfo;

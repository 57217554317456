import reishi from "../images/benefitsR.webp";
import lm from "../images/benefitsLM1.webp";
import st1 from "../images/benefitsST1.webp";

export const listOfBenefits = [
  {
    handle: "super-troop",
    title: "Super Troop",
    description: "",
    list: [
      "A healthy stress response",
      "Enhanced energy",
      "Mental clarity",
      "Optimal digestion and gut health",
      "Your immune system",
      "Antiviral and anti-inflammatory responses",
      "Optimal antioxidant levels",
    ],
    image: st1,
    research: [
      {
        title: "Cordyceps & oxidative stress,",
        link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4179184/",
      },
      {
        title: "Cordyceps sinensis & cerebral ischemia,",
        link: "https://pubmed.ncbi.nlm.nih.gov/31978767/",
      },
      {
        title: "Turkey tail & gut healt,",
        link: "https://pubmed.ncbi.nlm.nih.gov/25006989/",
      },
      {
        title: "Immune-enhancing with Maitake & Shiitake extracts,",
        link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC4202470/",
      },
      {
        title: "PSK & BRM",
        link: "https://pubmed.ncbi.nlm.nih.gov/2825669/",
      },
      {
        title: "Anti-Inflammatory Effects of Inonotus obliquus,",
        link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC2840610/",
      },
      {
        title: "Trametes versicolor & Alzheimer’s disease",
        link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6010034/",
      },
    ],
  },

  {
    handle: "lions-mane",
    title: "Lion's Mane",
    description: "",
    list: [
      "Slowed neurodegeneration",
      "Improved cognitive function",
      "Neuron growth",
      "Increased mental clarity and focus",
      "Enhanced memory",
      "Boost in energy",
      "The Nervous System",
      "Healthy inflammatory response",
    ],
    image: lm,
    research: [
      {
        title: "Chemistry, Nutrition & Health-Promoting Properties,",
        link: "https://pubmed.ncbi.nlm.nih.gov/26244378/",
      },
      {
        title: "Hericium erinaceus & Recognition Memory,",
        link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6521003/",
      },
      {
        title: "Hericium erinaceus on cognitive impairment,",
        link: "https://pubmed.ncbi.nlm.nih.gov/18844328/",
      },
      {
        title: "Hericium erinaceus on learning and memory,",
        link: "https://pubmed.ncbi.nlm.nih.gov/21383512/",
      },
      {
        title: "Hericium erinaceus edible & medicinal,",
        link: "https://pubmed.ncbi.nlm.nih.gov/23735479/",
      },
      {
        title: "Neurotrophic Properties,",
        link: "https://www.dl.begellhouse.com/journals/708ae68d64b17c52,034eeb045436a171,750a15ad12ae25e9.html",
      },
    ],
  },

  {
    handle: "reishi",
    title: "Reishi",
    description: "",
    list: [
      "A good night’s rest ",
      "A healthy stress response ",
      "The cardiovascular system",
      "Antiviral and and anti-inflammatory responses ",
      "Increased cognitive function",
      "The nervous system",
      "The immune system",
      "Protecting the body against viruses, bacteria, parasites etc.",
    ],
    image: reishi,
    research: [
      {
        title: "Ganoderma lucidum,",
        link: "https://pubmed.ncbi.nlm.nih.gov/17383716/",
      },
      {
        title: "Ganoderma lucidum in neurasthenia,",
        link: "https://pubmed.ncbi.nlm.nih.gov/15857210/",
      },
      {
        title: "Ganoderma lucidum Improves Cancer-Related Fatigue,",
        link: "https://pubmed.ncbi.nlm.nih.gov/22203880/",
      },
      {
        title:
          "Ganoderma lucidum as treatment of cardiovascular risk factors, ",
        link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC6486141/",
      },
      {
        title: "Suppression of inflammatory and allergic responses, ",
        link: "https://pubmed.ncbi.nlm.nih.gov/24948193/",
      },
      {
        title: "Lingzhi or Reishi,",
        link: "https://www.ncbi.nlm.nih.gov/books/NBK92757/",
      },
      {
        title: "Polysaccharides Promote Cognitive Function, ",
        link: "https://www.ncbi.nlm.nih.gov/pmc/articles/PMC5233449/",
      },
      {
        title: "Neuropharmacological Effect and Clinical Applications,",
        link: "https://pubmed.ncbi.nlm.nih.gov/31777017/",
      },
      {
        title: "Immune modulation,",
        link: "https://www.dl.begellhouse.com/journals/708ae68d64b17c52,66746bdc1bb430f2,249ed53b302a8d11.html",
      },
      {
        title: "Anticancer & Antidiabetic,",
        link: "https://pubmed.ncbi.nlm.nih.gov/30114634/",
      },
      {
        title: "Culinary-Medicinal Mushrooms for Antioxidant",
        link: "https://www.hindawi.com/journals/ecam/2012/464238/",
      },
    ],
  },
];

import pink from "../images/jelly-teddy-bear2.png";
import green from "../images/factsLM.webp";
import blue from "../images/jelly-teddy-bear-blue.png";

export const facts = [
  [
    {
      handle: "super-troop",
      image: pink,
      color: "#FFFAFD",
      ingredients: `OTHER INGREDIENTS: Tapioca Syrup, Organic Cane Sugar, Water, Pectin, 
      Organic Rice Starch (For Coating), Natural Fruit Flavors, Citric Acid, Sodium Citrate, 
      Color From Fruit & Vegetable Extract (Purple Sweet Potato).`,
    },
    {
      fact: "Calories",
      quantity: "20",
      daily: "",
      optional: "0",
    },
    {
      fact: "Total Carbohydrate",
      quantity: "5 g",
      daily: "2%*",
      optional: "0",
      list: [
        { fact: "Total Sugars", quantity: "3 g", daily: "**", optional: "0" },
        {
          fact: "Includes 2g Added Sugars",
          quantity: "",
          daily: "6%*",
          optional: "0",
        },
      ],
    },
    {
      fact: "Total Sugars",
      quantity: "3 g",
      daily: "**",
      optional: "0",
    },
    {
      fact: "Includes 2g Added Sugars",
      quantity: "",
      daily: "6%*",
      optional: "0",
    },
    {
      fact: "Sodium",
      quantity: "5 mg",
      daily: "< 1%",
      optional: "0",
    },

    {
      fact: "Proprietary Mushroom Blend Reishi Fruit Body (Ganoderma lucidum), Chaga Sclerotia (Inonotus obliquus), Lion's Mane Fruit Body (Hericuium erinaceus), Cordycepts Fruit Body (Cordyceps militaris), Maitake Fruit Body (Grifola frondose), Turkey Tail Fruit Body (Trametes versicolor).",
      quantity: "368 mg",
      daily: "",
      optional: "1",
    },
  ],

  [
    {
      handle: "lions-mane",
      image: green,
      color: "#F9FFE7",
      ingredients: `OTHER INGREDIENTS: Tapioca Syrup, Organic Cane Sugar, Water, Pectin, 
      Organic Rice Starch (For Coating), Natural Fruit Flavor, Citric Acid, Sodium Citrate, Annatto Extract (For Color).`,
    },
    {
      fact: "Calories",
      quantity: "20",
      daily: "",
      optional: "0",
    },
    {
      fact: "Total Carbohydrate",
      quantity: "5 g",
      daily: "2%*",
      optional: "0",
    },
    {
      fact: "Total Sugars",
      quantity: "3 g",
      daily: "**",
      optional: "0",
    },
    {
      fact: "Includes 2g Added Sugars",
      quantity: "",
      daily: "6%*",
      optional: "0",
    },
    {
      fact: "Sodium",
      quantity: "5 mg",
      daily: "< 1%",
      optional: "0",
    },

    {
      fact: "Lion's Mane Mushroom Fruit Body (Hericium erinaceus)",
      quantity: "369mg",
      daily: "",
      optional: "1",
    },
  ],

  [
    {
      handle: "reishi",
      image: blue,
      color: "#F4FEFF",
      ingredients: `OTHER INGREDIENTS: Tapioca Syrup, Organic Cane Sugar, Water, Pectin, 
      Organic Rice Starch (For Coating), Natural Fruit Flavor, Citric Acid, Sodium Citrate, 
      Color from Fruit Extract (Grape Skin).
      `,
    },
    {
      fact: "Calories",
      quantity: "20",
      daily: "",
      optional: "0",
    },
    {
      fact: "Total Carbohydrate",
      quantity: "5 g",
      daily: "2%*",
      optional: "0",
    },
    {
      fact: "Total Sugars",
      quantity: "3 g",
      daily: "**",
      optional: "0",
    },
    {
      fact: "Includes 2g Added Sugars",
      quantity: "",
      daily: "6%*",
      optional: "0",
    },
    {
      fact: "Sodium",
      quantity: "5 mg",
      daily: "< 1%",
      optional: "0",
    },

    {
      fact: "Reishi Mushroom Fruit Body (Ganoderma lucidum)",
      quantity: "368 mg",
      daily: "",
      optional: "1",
    },
  ],
];

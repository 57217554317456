export const getCheckbox = (
  price: number,
  currencyCode: string,
  discount: number,
  shouldRound = false
): { id: string; price: number; title: string }[] => {
  let discountedPrice = price * (1 - discount / 100);
  if (shouldRound) discountedPrice = Math.round(discountedPrice);
  const checkbox = [
    {
      id: "once",
      price: price,
      title: `<h1>Buy Once for ${currencyCode}${price}</h1>`,
    },
    {
      id: "sub",
      price: price,
      title: `<h1>Monthly for <span>${currencyCode}${price} </span><span>$${discountedPrice}</span></h1>`,
    },
  ];
  return checkbox;
};

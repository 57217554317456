// extracted by mini-css-extract-plugin
export var container = "QuestionWrapper-module--container--ZD+0A";
export var evergreen = "QuestionWrapper-module--evergreen--tpq-z";
export var faq = "QuestionWrapper-module--faq--eglzk";
export var moreQuestions = "QuestionWrapper-module--more-questions--Y0ipI";
export var questionsWrapper = "QuestionWrapper-module--questions-wrapper--Kl5CP";
export var smallbubble = "QuestionWrapper-module--smallbubble--0CGlv";
export var splitLeaf = "QuestionWrapper-module--split-leaf--VsYln";
export var text = "QuestionWrapper-module--text--jaOfU";
export var textWrapper = "QuestionWrapper-module--text-wrapper--EgiDH";
export var title = "QuestionWrapper-module--title--LiNUx";
export var wrapper = "QuestionWrapper-module--wrapper--6CRxW";
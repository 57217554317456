import React from "react";
import * as s from "./DemonstratedBenefits.module.scss";
import Question from "../../atoms/Question/Question";
import { listOfBenefits } from "../../../constants/demonstratedBenefits";
import { ImImage } from "react-icons/im";

const DemonstratedBenefits = ({ handle }: { handle?: string }) => {
  const benefit = listOfBenefits.filter((f) => f.handle === handle);
  const research = benefit[0]?.research;

  return (
    <div className={s.container}>
      <div className={s.textContainer}>
        <p className={s.title}>May support:</p>
        <p className={s.text}>{benefit[0]?.description}</p>
        <ul className={s.benefitList}>
          {benefit[0]?.list.map((l) => (
            <li key={l} className={s.benefitListItem}>
              {l}
            </li>
          ))}
        </ul>

        <Question
          question="Research and Studies"
          researchList={research}
          component="benefits"
          list={true}
        />
        <Question
          answer="These statements have not been evaluated by the Food and Drug Administration. This product is not intended to diagnose, treat, cure or prevent any disease. The views and nutritional advice expressed by Troop Nutrition are not intended to be a substitute for conventional medical service. If you have a severe medical condition, see your physician of choice. Individual results may vary."
          question="Disclaimer"
          component="benefits"
        />
      </div>
      <div className={s.imageContainer}>
        {benefit[0]?.image === undefined ? (
          <ImImage style={{ width: "100%", height: "2em" }} />
        ) : (
          <img src={benefit[0]?.image} alt="pilss" className={s.pills} />
        )}
      </div>
    </div>
  );
};

export default DemonstratedBenefits;

import Photo from "../images/Photo.png";
import img from "../images/rating.png";
export const REVIEW_CAP = 150;
export const reviews = [
  {
    id: 1,
    photo: Photo,
    name: "Jane Cooper",
    jobTitle: "CEO, ABC Corporation",
    country: "United State",
    position: "Verified buyer",
    rating: img,
    review: `We had an incredible experience using Super troop and were
        impressed they made such a big difference in our lifes. Our family
        is so grateful for the wonderful improvements they made It acted
        as a catalyst to take our health to the next level and get more
        involved in other Troop products.`,
  },
  {
    id: 2,
    photo: Photo,
    name: "Jane Cooper",
    jobTitle: "CEO, ABC Corporation",
    country: "United State",
    position: "Verified buyer",
    rating: img,
    review: `We had an incredible experience using Super troop and were
        impressed they made such a big difference in our lifes. Our family
        is so grateful for the wonderful improvements they made It acted
        as a catalyst to take our health to the next level and get more
        involved in other Troop products.`,
  },
  {
    id: 3,
    photo: Photo,
    name: "Jane Cooper",
    jobTitle: "CEO, ABC Corporation",
    country: "United State",
    position: "Verified buyer",
    rating: img,
    review: `We had an incredible experience using Super troop and were
        impressed they made such a big difference in our lifes. Our family
        is so grateful for the wonderful improvements they made It acted
        as a catalyst to take our health to the next level and get more
        involved in other Troop products.`,
  },
];

export const questions = [
  {
    question:
      "Wait… These are mushrooms… They aren’t going to make me trip, right?",
    answer:
      "<p>No way! These mushrooms do not contain psilocybin-- the compound that makes magic mushrooms trippy. Our mushrooms are intended to be taken and enjoyed daily—morning, noon and night.</p>",
  },
  {
    question: "What does the whole fruiting body mean?",
    answer:
      "<p>The whole fruiting body is the part of the mushroom that we all know, love and eat. It’s the stalk and cap of the mushroom that grows from the ground and is the reproductive structure rich in secondary metabolites that continues the life cycle of the fungi.<br /> The fruiting body contains many of the mushroom’s nutritional benefits. Think of the mushroom as the flower or fruit, and the underground mycelium as the root. We can go on and on, especially in regards to the incredible functions of mycelium. To read more about about the fruiting body, read here!</p>",
    link: "“WHOLE FRUITING BODY VS. MYCELIUM”",
    linkTo: "/blog-single/#whole-fruiting-body-vs-mycelium",
  },
  // {
  //   question: "Why is there alcohol content in your products?",
  //   answer:
  //     "<p>There is a trace amount of alcohol content due to the alcohol used to extract the fat-soluble, bioavailable compounds from the mushrooms. The amount is so insignificant that you get more alcohol in an overripe fruit than you do here, so you won’t be getting your buzz on.</p>",
  // },
  {
    question: "What's your return policy??",
    answer:
      "<p>While we don't accept returns, we have a 100% money back guarantee within 15 days of purchase.</p>",
  },
  {
    question: "Why is it important to have dual-extracted mushroom products?",
    answer:
      "<p>The bioavailable compounds of the mushroom are locked within its cell walls, called chitin. Chitin is actually the same substance that makes up crustacean shells! Needless to say this is a tough structure to penetrate. In order to get the nutrients out of the fruiting body, hot water and alcohol extractions are necessary to release the fat and water soluble compounds from the chitin.</p>",
  },
  {
    question:
      "Am I getting a proper serving size of mushrooms even though it’s in a gummy?",
    answer:
      "<p>There’s over 3 grams of fresh mushrooms per serving size of our gummies and we dual extract the mushrooms for maximum potency. Our extractions are tried and tested and pack a punch of beta-glucans and polysaccharides.</p>",
  },
  {
    question: "Are there any side-effects to taking medicinal mushrooms?",
    answer:
      "<p>While mushrooms are completely safe and nontoxic, we recommend consulting a physician before taking any supplements.</p>",
  },
  {
    question:
      " Can you guarantee that my ailments will go away because of these products?",
    answer:
      "<p>While functional mushrooms have been shown to help many ailments and have been used as medicines in various cultures for thousands of years, we cannot make any claims or guarantees about our products and our statements are not reviewed by the FDA.</p>",
  },
  {
    question: "Are your mushrooms organic?",
    answer:
      "<p>All of our mushrooms are cultivated in the US with organic and sustainable farming practices  (when not wild-harvested). They are tested to ensure they are free from toxins, pestisides and heavy metals.</p>",
  },
  {
    question: "What’s the best time of day to take my gummies?",
    answer:
      "<p>Great question! We recommend taking the Lion’s Mane gummy in the morning, Reishi at night and the Super Troop as a midday pick me up.</p>",
  },
  {
    question: "How do I get free shipping?",
    answer:
      "<p>We always have free shipping if you live in the continental US!</p>",
  },
  {
    question: "Can I freeze my subscription if I want to skip a month?",
    answer:
      "<p>You can freeze, pause or skip your subscription at any time!</p>",
  },
  {
    question: "How does my subscription work?",
    answer:
      "<p>Click on the subscribe and save button and you will automatically be enrolled in receiving the Troop product(s) of your choice discounted every month, delivered right to your door. Additionally, Troop subscribers should stay tuned for surprises ;)</p>",
  },
  {
    question: "How long will it take to start seeing results",
    answer:
      "<p>You can start feeling results as soon as one hour after taking your supplement!</p>",
  },
  {
    question: "Are Troop products vegan?",
    answer: "<p>Yes, our products are always 100% vegan!</p>",
  },
  {
    question: "Are Troop products FDA approved?",
    answer:
      "<p>Dietary supplements do not get approved by the FDA; however, we do follow the FDA guidelines in our efforts to remain compliant with its rules and regulations.</p>",
  },
  {
    question: "So what’s up with your packaging?",
    answer:
      "<p>We’re happy you asked! Our bottle is made out of 100% PCR (post consumer resin plastic) aka recycled plastic. We are committed to not introducing any new plastics into the world, and repurposing what already exists. Additionally we chose not to use glass for our bottle as glass rarely gets recycled, and actually incurs a higher carbon footprint when being manufactured than recycled plastic! Our boxes are made out of recycled cardboard and are printed with sustainable inks.</p>",
  },
];

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions */

import React from "react";
import * as s from "./LikeButton.module.scss";

interface ILikeButton {
  buttonText: string;
  votes?: number;
  icon?: string;
  onClick?: any;
}

const LikeButton = ({ buttonText, votes, icon, onClick }: ILikeButton) => {
  return (
    <div className={s.mainWrapper} onClick={onClick}>
      <button className={s.leftButton}>
        <img src={icon} alt="iconImg"></img>
        {buttonText}
      </button>
      <button className={s.rightButton}>{votes}</button>
    </div>
  );
};

export default LikeButton;

import React from "react";
import * as s from "./ScoreBar.module.scss";
import star from "../../../images/Star.png";

interface ScoreBarProps {
  scoreNumber: number;
  percent: number;
  handleClickStar?: () => void;
}

const ScoreBar = ({ scoreNumber, percent, handleClickStar }: ScoreBarProps) => {
  return (
    <div
      className={s.container}
      style={{ cursor: percent ? "pointer" : "initial" }}
      onClick={handleClickStar}
      role="presentation"
    >
      <div className={s.starAndNumber}>
        <p className={s.number}>{scoreNumber}</p>
        <img src={star} alt="Star Icon" />
      </div>
      <div
        className={percent ? s.bar : s.barNotActive}
        style={{
          background: `linear-gradient(to right, #16b76a 0 ${percent}%, #E1F8ED ${percent}% 100%)`,
        }}
      ></div>
      <div className={s.percent}>{percent}%</div>
    </div>
  );
};

export default ScoreBar;

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions, jsx-a11y/no-static-element-interactions */
import React, { useState } from "react";
import * as s from "./Expander.module.scss";
import GreenArrow from "../../../images/GreenArrowExpander.svg";
import GrayArrow from "../../../images/GrayArrow.svg";

interface QuestionProps {
  title: string;
  description?: string;
}

const Expander = ({ title, description }: QuestionProps) => {
  const [show, setShow] = useState(false);

  const handleClick = () => {
    setShow(!show);
  };

  return (
    <div className={s.container}>
      <div className={s.titleContainer} onClick={handleClick}>
        <div className={s.iconWrapper} onClick={handleClick}>
          {show ? (
            <img src={GreenArrow} alt="plus" />
          ) : (
            <img src={GrayArrow} alt="minus" />
          )}
        </div>
        <p className={s.title} onClick={handleClick}>
          {title}
        </p>
      </div>
      {show && (
        <>
          <p className={s.description}>{description}</p>
        </>
      )}
    </div>
  );
};

export default Expander;

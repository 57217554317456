/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import React, { useEffect, useState } from "react";
import * as s from "./FullProduct.module.scss";
import ProductInfo from "../../molecules/ProductInfo/ProductInfo";
import DemonstratedBenefits from "../../molecules/DemonstratedBenefits/DemonstratedBenefits";
import QuestionWrapper from "../QuestionWrapper/QuestionWrapper";
import NutritionFacts from "../../molecules/NutritionFacts/NutritionFacts";
import { ProductsHome } from "../ProductsHome/ProductsHome";
import ShroomsCardPage from "../../molecules/ShroomsCardPage/ShroomsCardPage";
import MushroomComponent from "../../atoms/MushroomComponet/MushroomComponent";
import ReviewsPage from "../Reviews&QA/Reviews&QA";
import image from "../../../images/Vector circle.png";
import image1 from "../../../images/shroomvector.png";
import IconWrapperProduct from "../../molecules/IconWrapperProduct/IconWrapperProduct";
import ProductPackageCard from "../../molecules/ProductPackageCard/ProductPackageCard";
import { useGetAllShopifyProducts } from "../../../g/queries/get-all-shopify-products";
import { REVIEW_CAP } from "../../../constants/reviews";
import {
  getAllReviewsForTroop,
  getProductReviews,
  getProductReviewsPerPage,
} from "../../../constants/yotpoReviews";
import * as _ from "lodash";

interface FullProductProps {
  product: Product;
}

interface IReview {
  content: string;
  created_at?: string;
  custom_fields: null;
  deleted?: boolean;
  id?: number;
  product_id: number;
  score: number;
  sentiment: number;
  source_review_id: null;
  title: string;
  user: {
    user_id: number;
    social_image: null;
    user_type: string;
    is_social_connected: number;
    display_name: string;
  };
  verified_buyer: boolean;
  votes_down: number;
  votes_up: number;
}

const FullProduct = ({ product }: FullProductProps) => {
  const [reviews, setReviews] = useState<IReview[]>([]);
  const { allShopifyProduct } = useGetAllShopifyProducts();
  const [currentPage, setCurrentPage] = useState(1);
  const prepaidProducts = allShopifyProduct.edges.filter(
    (el: ProductNode) => el.node.productType === "Prepaid Gummies"
  );
  const [threeMonths, setThreeMonths] = useState<ProductNode>();
  const [sixMonths, setSixMonths] = useState<ProductNode>();
  const superPack = allShopifyProduct.edges.find(
    (el: ProductNode) => el.node.title === "Super Pack"
  );

  useEffect(() => {
    const threeMonthsProd = prepaidProducts.filter((el: ProductNode) =>
      el.node.title.includes("3")
    );
    const sixMonthsProd = prepaidProducts.filter((el: ProductNode) =>
      el.node.title.includes("6")
    );
    if (product.handle) {
      const threeMonthsProduct = threeMonthsProd.find((el: ProductNode) =>
        el?.node?.handle?.includes(product?.handle as string)
      );
      setThreeMonths(threeMonthsProduct);
      const sixMonthsProduct = sixMonthsProd.find((el: ProductNode) =>
        el?.node?.handle?.includes(product?.handle as string)
      );
      setSixMonths(sixMonthsProduct);
    }
  }, []);

  const getReviews = async () => {
    if (currentPage === -1) return;

    const yotpoKey = process.env.GATSBY_YOTPO_APP_KEY || "";

    const result = await getProductReviewsPerPage(
      yotpoKey,
      product.shopifyId || "",
      currentPage.toString(),
      REVIEW_CAP.toString()
    );
    try {
      if (result.reviews.length) {
        setCurrentPage(currentPage + 1);
        setReviews((prev: any) => [...prev, ...result.reviews]);
      } else {
        setCurrentPage(-1);
      }
    } catch (e) {
      console.log(e);
      setCurrentPage(-1);
    }
  };

  const saveReviews = () => {
    let reviewsFromStorage = localStorage.getItem("productReviews");

    if (!reviewsFromStorage) {
      localStorage.setItem("productReviews", "{}");
      reviewsFromStorage = localStorage.getItem("productReviews");
      // first time creating the object
      const parsedReviews = JSON.parse(reviewsFromStorage || "");
      parsedReviews[product.title] = reviews?.map((item) => ({
        ...item,
        liked: false,
        disliked: false,
      }));

      // add to local storage
      localStorage.setItem("productReviews", JSON.stringify(parsedReviews));
    } else {
      const parsedReviews = JSON.parse(reviewsFromStorage || "")[product.title];
      if (!parsedReviews) {
        // add new product
        const prodReviews = JSON.parse(reviewsFromStorage || "");
        prodReviews[product.title] = reviews;
        localStorage.setItem("productReviews", JSON.stringify(prodReviews));
      } else if (parsedReviews && parsedReviews.length !== reviews?.length) {
        // there are new reviews present, localstorage should be updated
        updateReviews();
      }
    }
  };

  const updateReviews = () => {
    const reviewsFromStorage = localStorage.getItem("productReviews");
    const allReviewsObject = JSON.parse(reviewsFromStorage || "");
    reviews?.forEach((item) => {
      let found = false;
      allReviewsObject[product.title].forEach((pItem: any) => {
        if (pItem.id === item.id) found = true;
      });

      if (!found) {
        allReviewsObject[product.title].push({
          ...item,
          liked: false,
          disliked: false,
        });
      }
    });

    // set localstorage
    localStorage.setItem("productReviews", JSON.stringify(allReviewsObject));
  };

  useEffect(() => {
    // keep fetching reviews until all are fetched
    if (currentPage > 0) getReviews();
    else saveReviews();
  }, [currentPage]);

  return (
    <div className={s.wrapper}>
      <ProductInfo product={product} reviews={reviews} />
      <div className={s.packageContainer}>
        <ProductPackageCard
          title="Super Pack"
          haveOption={true}
          product={superPack as ProductNode}
        />
        <ProductPackageCard
          title="3 months of one"
          haveOption={false}
          product={threeMonths as ProductNode}
          price={Number(product?.variants[0].price)}
        />
        <ProductPackageCard
          title="6 months of one"
          haveOption={false}
          product={sixMonths as ProductNode}
          price={Number(product?.variants[0].price)}
        />
      </div>
      <DemonstratedBenefits handle={product?.handle} />
      <QuestionWrapper productsPage={true} />

      <NutritionFacts product={product} />
      <div className={s.iconWrapper}>
        <IconWrapperProduct productName={product.handle} />
      </div>

      {product.handle === "super-troop" ? (
        <div className={s.componentWrapper}>
          <img src={image} className={s.image} alt="bubble"></img>
          <p className={s.title}>MEET YOUR MUSHROOMS</p>
          <p className={s.description}></p>
          <img src={image1} className={s.image1} alt="bubble"></img>
          <ShroomsCardPage page="product" />
        </div>
      ) : (
        <MushroomComponent product={product} />
      )}

      <ProductsHome page="single" filter={product} />

      {reviews?.length ? (
        <ReviewsPage reviews={reviews} product={product} />
      ) : (
        <></>
      )}
    </div>
  );
};

export { FullProduct };

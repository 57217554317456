import { Buffer } from "buffer";

export const decodeBase64 = (obj: string) => {
  // base64 to string
  const base64ToString = Buffer.from(obj, "base64");
  return base64ToString.toString().split("/").pop();
};

export const getAverageScore = async (
  yotpoAppKey: string,
  productId: string
) => {
  const pr = decodeBase64(productId);

  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const response = await fetch(
    `https://api.yotpo.com/products/${yotpoAppKey}/${pr}/bottomline`,
    options
  );

  if (!response) throw new Error("Something went wrong!");
  const responseBody = await response.json();
  const reviews = responseBody.response || [];
  return await reviews;
};

export const getAverageScoreForAllProducts = async (yotpoAppKey: string) => {
  const options = {
    method: "GET",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
  };

  const response = await fetch(
    `https://api.yotpo.com/products/${yotpoAppKey}/yotpo_site_reviews/bottomline`,
    options
  );

  if (!response) throw new Error("Something went wrong!");
  const responseBody = await response.json();
  const reviews = responseBody.response || [];
  return await reviews;
};

import React, { useEffect, useState } from "react";
import * as s from "./NutritionFacts.module.scss";
import { facts } from "../../../constants/nutritionFacts";
import NutritionFact from "../../atoms/NutritionFact/NutritionFact";
import { ImImage } from "react-icons/im";
import classNames from "classnames";

const cn = classNames.bind(s);

interface FullProductProps {
  product: Product;
}

interface Fact {
  handle?: string;
  image?: string;
  color?: string;
  ingredients?: string;
  fact: string;
  quantity: string;
  daily?: undefined;
  optional: string;
}

const NutritionFacts = ({ product }: FullProductProps) => {
  const [image, setImage] = useState<string | undefined>();
  const [color, setColor] = useState<string | undefined>();
  const [ingredients, setIngredients] = useState<string | undefined>();
  const [fact, setFact] = useState<Fact[]>();

  const setJellyImage = () => {
    facts.map((f: any) => {
      if (f[0].handle === product.handle) {
        setImage(f[0].image);
        setColor(f[0].color);
        setIngredients(f[0].ingredients);
        setFact(f);
      }
    });
  };

  useEffect(() => {
    setJellyImage();
  }, [product]);

  return (
    <div className={s.container}>
      <div className={s.wrapper} style={{ backgroundColor: color }}>
        <div className={s.imageWrapper}>
          {image === undefined ? (
            <ImImage style={{ width: "100%", height: "2em" }} />
          ) : (
            <img src={image} alt="jelly-bear" />
          )}
        </div>
        <div className={s.textWrapper}>
          <div className={s.titleWrapper}>
            <p className={s.title}>Supplement facts</p>
          </div>
          <div className={s.textWrapperOne}>
            <div className={s.supplementWrapper}>
              <p className={s.subtitle}>Serving Size 2 Gummies (6g)</p>
              <p className={s.subtitle}>Servings Per Container 30</p>
            </div>
            <p className={s.daily}>Amount per serving</p>
            <p className={s.daily}>% Daily Value</p>
          </div>
          <div className={s.factsWrapper}>
            {fact &&
              fact.map((f: Fact) => (
                <NutritionFact
                  key={f.fact}
                  fact={f.fact}
                  quantity={f.quantity}
                  daily={f.daily}
                  optional={f.optional}
                />
              ))}
          </div>
          <p className={s.bottomText}>
            *Percent Daily Values are based on a 2,000 calorie diet.
          </p>
          <p className={cn(s.bottomText, s.bottomText1)}>
            **Daily Value not established.
          </p>
          <p className={s.ingredients}>{ingredients}</p>
        </div>
      </div>
    </div>
  );
};

export default NutritionFacts;

import React from "react";
import { FullProduct } from "../components/organisms/FullProduct/FullProduct";

const ProductTemplate = (props: ProductTemplateProps) => {
  const product = props.pageContext.product;
  return (
    <div>
      <FullProduct product={product} />
    </div>
  );
};

export default ProductTemplate;

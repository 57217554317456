import React from "react";
import IconWrapper from "../IconWrapper/IconWrapper";
import * as s from "./IconWrapperProduct.module.scss";

interface IconWrapperProductProps {
  productName?: string;
}

const IconWrapperProduct = ({ productName }: IconWrapperProductProps) => {
  return (
    <div className={s.mainWrapper}>
      <IconWrapper page="product" productName={productName} />
    </div>
  );
};

export default IconWrapperProduct;

// extracted by mini-css-extract-plugin
export var bottomText = "NutritionFacts-module--bottom-text--wB4IW";
export var bottomText1 = "NutritionFacts-module--bottom-text-1--dVw24";
export var container = "NutritionFacts-module--container--YhCxH";
export var daily = "NutritionFacts-module--daily--M4j1D";
export var factsWrapper = "NutritionFacts-module--facts-wrapper--2CfLD";
export var imageWrapper = "NutritionFacts-module--image-wrapper--ExSB6";
export var ingredients = "NutritionFacts-module--ingredients--cRiqg";
export var subtitle = "NutritionFacts-module--subtitle--EXFMC";
export var supplementWrapper = "NutritionFacts-module--supplement-wrapper--EUp7Q";
export var textWrapper = "NutritionFacts-module--text-wrapper--ssxLh";
export var textWrapperOne = "NutritionFacts-module--text-wrapper-one--qqAnF";
export var title = "NutritionFacts-module--title--m0Cdc";
export var titleWrapper = "NutritionFacts-module--title-wrapper--+azym";
export var wrapper = "NutritionFacts-module--wrapper--6O3+6";